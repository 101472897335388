var Web3 = require('web3');
var ABI = require('../MonkeyBrix.json')
const bigNumber = require('bignumber.js')
var web3 = new Web3(window['ethereum']);
let contractAddress = "0xeD27195Fc5886a3ce81Ba66EdD0FEefCC512c15A";
var itemPrice = new bigNumber("80000000000000000");

///////////CHANGE THIS/////////////////b
async function connectButtonPress(){
    window.open("https://opensea.io/collection/monkeybrix");
    
}

async function buyButtonPress(){
    
    if (!contractAddress){
        alert('Please come back at 6pm EST on July 18th, 2021')
    } else {
        if (this.buyCount <= 0 || this.buyCount > 20){
            alert('Please input a valid number (1-20)')
        } else {
            let contract = new web3.eth.Contract(ABI, contractAddress)
            let sumAmount = itemPrice.multipliedBy(this.buyCount) 
            var self = this
            contract.methods.mintMonkey(this.buyCount).estimateGas({from: this.address, value:sumAmount})
            .then(function(gasAmount){
                let gasLimit = new bigNumber.BigNumber(gasAmount).multipliedBy(110).dividedBy(100).toFixed(0)
                let res =  contract.methods.mintMonkey(self.buyCount).send({value:sumAmount, from:self.address, gasLimit:gasLimit})
                res.then((res)=>{alert(`Transaction Sent: tx hash ${res.transactionHash}`)})
            })
            
            
            alert('Press OK to continue to approve the transaction in Metamask and then wait for the transaction to be sent...')
    
            
        }
    }
    
}

// async connectMetaMask(){
//     const address = await window['ethereum'].enable();
//     return address.length? address[0]: null;
// }
export default {
    connectButtonPress:connectButtonPress,
    buyButtonPress:buyButtonPress
};