<template>
  <div id="app" class="clear-fix site-wrapper">
    <Header :current="'home'" :data="this.header"></Header>
    <main id="page" class="container">
      <article id="sections" class="sections">
        <Greeting :data="this.greeting"></Greeting>
        <SoldOut :data="this.soldOut"></SoldOut>
        <Introduce :data="this.introduce"></Introduce>
        <WhatsYourSeven :data="this.whatsYourSeven"></WhatsYourSeven>
        <Roadmap :data="this.roadmap"></Roadmap>
        <Team :data="this.team"></Team>
      </article>
    </main>
    <Footer :data="this.footer"></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Greeting from "@/components/Greeting";
import SoldOut from "@/components/SoldOut";
import Introduce from "@/components/Introduce";
import WhatsYourSeven from "@/components/WhatsYourSeven";
import Roadmap from "@/components/Roadmap";
import Team from "@/components/Team";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  data() {
    return {
      header: {
        logo: '/assets/logo.png',
      },
      greeting: {
        banner: {
          desktop: "/assets/HeroBanner-desktop.png",
          mobile: "/assets/HeroBanner-mobile.png"
        },
        welcome: "Welcome to MonkeyBrix Studios",
        spec: "We just made an animation with your MonkyBrix NFTs!",
        content: [
            
        ]
      },
      soldOut: {
        title: "Buy Here!",
        content: "The initial sale has ended. To get your MonkeyBrix, check out the collection on OpenSea by clicking on BUY MonkeyBrix below.",
        heroImage: "/assets/012.png"
      },
      introduce: {
        heroImage: "/assets/intro01.png",
        hero2: {
          left: "/assets/17.png",
          right: "/assets/26.png"
        },
        features: [
          {
            subject: "MonkeyBrix",
            description: "MonkeyBrix are unique NFT collectibles programmatically generated and stored as ERC-721 on Ethereum by IPFS. Each MonkeyBrix is a unique superhero character that can appear on our Superhero Show. Superheroes x Monkeys x Brix. Badass."
          },
          {
            subject: "Traits",
            description: "MonkeyBrix are generated from nearly 200 possible Superhero traits. Who doesn’t want Superhero traits? "
          }
        ],
        features2: [
          {
            subject: "Show",
            description: "Our pilot show is MonkeyBrix 7, a story about assembling a league of 7 Superhero MonkeyBrix to face an imminent threat. The cast for the show will be selected from freshly minted MonkeyBrix. Assemble your seven!"
          },
          {
            subject: "Studio Membership",
            description: "Owning a MonkeyBrix also makes you a studio member where you can participate in activities that can feature your MonkeyBrix. Join our Discord or follow us on Twitter."
          }
        ]
      },
      whatsYourSeven: {
        heroImage: "/assets/whatsyourseven.png"
      },
      roadmap: {
        title: "Roadmap",
        heroImage: {
          left: "/assets/31.png",
          right: "/assets/32.png"
        },
        features: [

          {
            subject: "Completed",
            description: "All wallets with 7 or more MonkeyBrix at this time will each be airdropped a unique NFT collectible."
          },
          {
            subject: "Completed",
            description: "All wallets with 77 or more MonkeyBrix at this time will each be airdropped a super rare and unique NFT collectible."
          },
          {
            subject: "Completed",
            description: "Some MonkeyBrix will be selected for show promotion."
          },
          {
            subject: "Completed",
            description: "Some MonkeyBrix will be selected for the show."
          }
        ],
        units: [
            '/units/unit_3.png',
            '/units/unit_4.png',
            '/units/unit_5.png',
            '/units/unit_7.png',
            '/units/unit_8.png',
            '/units/unit_9.png',
            '/units/unit_10.png',
            '/units/unit_11.png',
            '/units/unit_12.png',
            '/units/unit_13.png',
        ]
      },
      team: {
        content: "This is a BrixStudios project." +
            "\nWe are creators, designers and developers.\nSm@llest:  World Creator\nDev_PAF:  Development Genie\nxyAPE:  Master of Production\nWil-o-Monkey:  Omni Moderator" 
      },
      footer: {
        link: {
          twitter: "https://twitter.com/MonkeyBrix",
          discord: "https://discord.gg/PytJDTJEKp",
          instagram: "https://www.instagram.com/MonkeyBrix",
        },
        logo: "/assets/logo.png",
        copyright: "All Rights Reserved, MonkeyBrix 2021",
        terms: {
          link: "/terms-and-conditions"
        },
      }
    }
  },
  components: {
    Header,
    Greeting,
    SoldOut,
    Introduce,
    WhatsYourSeven,
    Roadmap,
    Team,
    Footer,
  }
}
</script>

<style>

</style>
